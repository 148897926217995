import React from 'react';
import part1Pdf from "../assets/part1.pdf";
import part2Pdf from "../assets/part2.pdf";
import part3Pdf from "../assets/part3.pdf";
import epilogPdf from "../assets/epilog.pdf";
import '../styles/Legend.css';

function Legend() {
  return (
    <section className="legend">
      <h2 className="legend__title">ЛЕГЕНДА</h2>
      
      <p className="legend__description">
        Наконец, свет увидели остальные части легенды этого года. Надеемся, что захватывающий сюжет не даст вам соскучиться в ожидании очных этапов!
      </p>

      <div className="legend__items">
        <div className="legend__item">
          <a href={part1Pdf} target="_blank" rel="noopener noreferrer" className="legend__item--icon-link">
            <div className="legend__item--icon legend__item--icon-blue"></div>
          </a>
          <h3 className="legend__item--title">ГЛАВА 1</h3>
        </div>

        <div className="legend__item">
          <a href={part2Pdf} target="_blank" rel="noopener noreferrer" className="legend__item--icon-link">
            <div className="legend__item--icon legend__item--icon-red"></div>
          </a>
          <h3 className="legend__item--title">ГЛАВА 2</h3>
        </div>

        <div className="legend__item">
          <a href={part3Pdf} target="_blank" rel="noopener noreferrer" className="legend__item--icon-link">
            <div className="legend__item--icon legend__item--icon-blue"></div>
          </a>
          <h3 className="legend__item--title">ГЛАВА 3</h3>
        </div>

        <div className="legend__item">
          <a href={epilogPdf} target="_blank" rel="noopener noreferrer" className="legend__item--icon-link">
            <div className="legend__item--icon legend__item--icon-red"></div>
          </a>
          <h3 className="legend__item--title">ЭПИЛОГ</h3>
        </div>
      </div>
    </section>
  );
}
export default Legend;