import React from "react";
import "../styles/Partners.css";
import organizer1 from "../assets/partners/organizer1.png";
import organizer2 from "../assets/partners/organizer2.png";
import partner1 from "../assets/partners/image.png";
import partner2 from "../assets/partners/image-1.png";
import partner4 from "../assets/partners/tech_partners1.png";
import partner5 from "../assets/partners/tech_partners2.png";
import partner6 from "../assets/partners/tech_partners3.png";
import partner7 from "../assets/partners/support1.png";
import partner8 from "../assets/partners/support2.png";
import partner9 from "../assets/partners/support3.png";
import partner10 from "../assets/partners/support4.png";
import partner11 from "../assets/partners/support5.png";
import partner12 from "../assets/partners/support6.png";
import partner13 from "../assets/partners/support7.png";
import partner14 from "../assets/partners/support8.png";
import partner15 from "../assets/partners/support9.png";
import partner16 from "../assets/partners/support10.png";
import aquarius from "../assets/partners/aquarius.png";
import logoa from "../assets/partners/202a.png";
import tass from "../assets/partners/tass.png";
import xaker from "../assets/partners/xaker.png";
import ctfnews from "../assets/partners/ctfnews.png";
import cybermedia from "../assets/partners/cybermedia.png";
import gpb from "../assets/partners/gpb.png";
import logo from "../assets/partners/logo.png";
import itplanet from "../assets/partners/logo_planet_white 1.png";
import solar from "../assets/partners/solar.png";
import staffcop from "../assets/partners/staffcop.png";
import cloud_ru from "../assets/partners/cloud_ru.png";
import tbank from "../assets/partners/tbank.png";
import trinity from "../assets/partners/trinity.png";
import newno from "../assets/partners/newmo.png";
import cintera from "../assets/partners/cintera.png";
import usergate from "../assets/partners/usergate.png";
import echo_losey from "../assets/partners/echo_losey.png";
import searchinform from "../assets/partners/searchinform.png";
import old_school_ctf from "../assets/partners/old_school_cft.png";

import { Link } from "react-router-dom";

function Partners() {
  return (
    <div className="partners">
      <div className="partners__header">
        <h1 className="partners__title">ПАРТНЁРЫ</h1>
      </div>
      <div className="partners__background">
        <div className="partners__block">
          <div className="partners__left">
            <div className="partners__content">
              <div className="partners__organizers">
                <h2 className="partners__subtitle">Организаторы</h2>
                <div className="partners__organizers-border">
                  <div className="partners__organizers-content">
                    <div to="https://aciso.ru" target="_blank">
                      <img
                        src={organizer2}
                        alt="Arsib"
                        className="partners__organizer-image partners__organizer-image--arsib"
                      />
                    </div>
                    <div to="https://nastavnikirossii.ru" target="_blank">
                      <img
                        src={organizer1}
                        alt="Nastavniki"
                        className="partners__organizer-image partners__organizer-image--nastavniki"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="partners__join">
                <div className="partners__join-border">
                  <div>
                    <h3 className="partners__join-title">
                      Стань частью VIII Кубка CTF
                    </h3>
                    <p className="partners__join-text">
                      Приглашаем к сотрудничеству ИТ и ИБ-компании. Напишите
                      нам, если хотите поддержать развитие сообщества
                      информационной безопасности России.
                    </p>
                    <div to="mailto:info@aciso.ru">
                      <button className="partners__join-button">
                        Обсудить сотрудничество
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="partners__right">
            <div className="partners__official">
              <h2 className="partners__official-title">Генеральный партнёр</h2>
              <div className="partners__official-border">
                <div>
                  <div to="https://www.aq.ru" target="_blank">
                    <img
                      src={aquarius}
                      alt="Aquarius"
                      className="partners__partner-image"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="partners__official">
              <h2 className="partners__official-title">Официальный партнёр</h2>
              <div className="partners__official-border">
                <div>
                  <div to="https://www.gazprombank.ru" target="_blank">
                    <img
                      src={gpb}
                      alt="Gazprombank"
                      className="partners__partner-image"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="partners__official">
              <h2 className="partners__official-title">Официальный партнёр</h2>
              <div className="partners__official-border">
                <div className="sber">
                  <Link to="https://www.sberbank.com/ru/ecs" target="_blank">
                    <img
                      src={partner1}
                      alt="Sber"
                      className="partners__partner-image"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="partners__tech-section">
          <div className="partners__partners-section">
            <h2 className="partners__subtitle">Партнёры</h2>
            <div className="partners__partners-border">
              <div className="parntners__block">
                <div to="https://cctld.ru" target="_blank">
                  <img
                    src={partner2}
                    alt="CCtld"
                    className="partners__partner-image"
                  />
                </div>
                <div
                  to="https://cintera.ru"
                  target="_blank"
                  rel="noopener noreferer"
                  className="cintera"
                >
                  <img
                    src={cintera}
                    alt="Цинтера"
                    className="partners__tech-partner-image"
                  />
                </div>
                <div
                  to="https://searchinform.ru"
                  target="_blank"
                  rel="noopener noreferer"
                  className="Seachinform"
                >
                  <img
                    src={searchinform}
                    alt="Seachinform"
                    className="partners__tech-partner-image-searchinform"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="partners__tech-partners-section">
            <h2 className="partners__tech-subtitle">
              Технологические партнёры
            </h2>
            <div className="partners__tech-partners-border">
              <div className="partners__tech-partners-content">
                <div
                  to="https://cbsctf.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={partner4}
                    alt="CBS logo"
                    className="partners__tech-partner-image"
                  />
                </div>
                <div
                  to="https://ntc-vulkan.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={partner5}
                    alt="Partner 5"
                    className="partners__tech-partner-image"
                  />
                </div>
                <img
                  src={partner6}
                  alt="Phase logo"
                  className="partners__tech-partner-image"
                />
                <div
                  to="https://202a.easyweek.ru"
                  target="_blank"
                  rel="noopener noreferer"
                >
                  <img
                    src={logoa}
                    alt="202a"
                    className="partners__tech-partner-image"
                  />
                </div>
              </div>
              <div className="partners__tech-cloud">
                <div
                  to="https://cloud.ru"
                  target="_blank"
                  rel="noopener noreferer"
                  className="cloud_ru"
                >
                  <img
                    src={cloud_ru}
                    alt="Cloud.ru"
                    className="partners__tech-partner-image"
                  />
                </div>
                <div
                  to="https://www.usergate.com/ru/guideline"
                  target="_blank"
                  rel="noopener noreferer"
                  className="Usergate"
                >
                  <img
                    src={usergate}
                    alt="Usergate"
                    className="partners__tech-partner-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="partners__tech-section">
          <div className="partners__partners-section partners__partners-tass">
            <h2 className="partners__subtitle partners__subtitle-general">
              Генеральное информационное агенство
            </h2>
            <div className="partners__partners-border partners__partners-general_border">
              <div>
                <div to="https://tass.ru/" target="_blank">
                  <img
                    src={tass}
                    alt="Tass"
                    className="partners__partner-image"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="partners__tech-partners-section partners__tech-prize">
            <h2 className="partners__tech-subtitle">
              Партнёры призового фонда
            </h2>
            <div className="partners__tech-partners-border">
              <div className="partners__tech-partners-content">
                <div
                  to="https://rt-solar.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={solar}
                    alt="Solar"
                    className="partners__tech-partner-image"
                  />
                </div>
                <div
                  to="https://www.staffcop.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={staffcop}
                    alt="Staffcop"
                    className="partners__tech-partner-image"
                  />
                </div>
                <div
                  to="https://tbank.ru"
                  target="_blank"
                  rel="noopener noreferer"
                >
                  <img
                    src={tbank}
                    alt="Т-Банк"
                    className="partners__tech-partner-image"
                  />
                </div>

                <div
                  to="https://xn--h1aaasnle.xn--p1ai"
                  target="_blank"
                  rel="noopener noreferer"
                >
                  <img
                    src={trinity}
                    alt="Тринити"
                    className="partners__tech-partner-image"
                  />
                </div>


                <div
                  to="https://xn--h1aaasnle.xn--p1ai"
                  target="_blank"
                  rel="noopener noreferer"
                >
                  <img
                    src={old_school_ctf}
                    alt="Old School Ctf"
                    className="partners__tech-partner-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="partners__tech-section">
          <div className="partners__tech-partners-section partners__tech-information">
            <h2 className="partners__tech-subtitle">Информационные партнёры</h2>
            <div className="partners__tech-partners-border">
              <div className="partners__tech-partners-content">
                <div
                  to="https://ctfnews.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={ctfnews}
                    alt="Ctfnews"
                    className="partners__tech-partner-image"
                  />
                </div>

                <div
                  to="https://securitymedia.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={cybermedia}
                    alt="Cybermedia"
                    className="partners__tech-partner-image"
                  />
                </div>

                <div
                  to="https://world-it-planet.org"
                  target="_blank"
                  rel="noopener noreferer"
                >
                  <img
                    src={itplanet}
                    alt="It Planet"
                    className="partners__tech-partner-image"
                  />
                </div>
                <div
                  to="https://xakep.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={xaker}
                    alt="Xaker"
                    className="partners__tech-partner-image"
                  />
                </div>
                <div
                  to="https://newm-project.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="newno_link"
                >
                  <img
                    src={newno}
                    alt="Newno"
                    className="partners__tech-partner-image"
                  />
                </div>
                <div
                  to="https://ocs-radio.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={echo_losey}
                    alt="Echo losey"
                    className="newno_link"
                  />
                </div>
              </div>
              <div className="newno">
                <div
                  to="https://newm-project.ru"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={newno}
                    alt="Newno"
                    className="partners__tech-partner-image"
                  />
                </div>
                <div
                  to="https://ocs-radio.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={echo_losey}
                    alt="Echo losey"
                    className="partners__tech-partner-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="partners__support-section">
          <h2 className="partners__support-subtitle">При поддержке</h2>
          <div className="partners__support-border">
            <div className="partners__support-grid">
              <img
                src={partner7}
                alt="Partner 7"
                className="partners__support-image partners__support-image-1"
              />
              <img
                src={partner8}
                alt="Partner 8"
                className="partners__support-image partners__support-image-2"
              />
              <img
                src={partner9}
                alt="Partner 9"
                className="partners__support-image partners__support-image-3"
              />
              <img
                src={partner10}
                alt="Partner 10"
                className="partners__support-image partners__support-image-4"
              />
              <img
                src={partner11}
                alt="Partner 11"
                className="partners__support-image partners__support-image-5"
              />
              <img
                src={partner12}
                alt="Partner 12"
                className="partners__support-image partners__support-image-6"
              />
              <img
                src={partner13}
                alt="Partner 13"
                className="partners__support-image partners__support-image-7"
              />
              <img
                src={partner14}
                alt="Partner 14"
                className="partners__support-image partners__support-image-8"
              />
              <img
                src={partner15}
                alt="Partner 15"
                className="partners__support-image partners__support-image-9"
              />
              <img
                src={partner16}
                alt="Partner 16"
                className="partners__support-image partners__support-image-10"
              />
            </div>
          </div>
        </div>

        <div className="partners__info-section">
          <div className="partners__info-border">
            <div className="partners__info-content">
              <div className="partners__info-icon">
                <img src={logo} />
              </div>
              <p className="partners__info-text">
                Кубок CTF ежегодно собирает несколько тысяч молодых
                айти-специалистов: в отборочном туре участвуют свыше 300 команд
                студентов и молодых профессионалов, а финал помогает выявить
                самых талантливых участников. Поддержка такого мероприятия
                помогает с развитием HR-бренда, повышает узнаваемость компании и
                закрывает маркетинговые потребности организаций любого масштаба.
              </p>
            </div>
          </div>
        </div>

        <div className="partners__decorations">
          <div className="partners__decoration partners__decoration--red"></div>
          <div className="partners__decoration partners__decoration--blue"></div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
